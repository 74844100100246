<template>
    <div>
        <h2 class="head font-poppins-semibold fs-lg-4 mt-3 text-primary">Fodder Distribution</h2>
       <s-form @submit="addItem">
        <div class="col-lg-2 mt-4">
            <validated-date-picker format="DD-MM-YYYY" class="text-black c-input-datepicker field-required" v-model="model.distribution_date" label="Date" :rules="rules.common"></validated-date-picker>
        </div>
        <div class="col-lg-12 ">
            <div class="row">
            <div class="col-lg-2">
                <validated-vue-select class="text-black c-input-select " :options="cropOptions" v-model="model.crop_variety" label="Crop/Variety/Type" :rules="{required:true}"></validated-vue-select>
            </div>
            <div class="col-lg-2">
                 <validated-ajax-vue-select label="Shed"
                                                   :url="shedOptionsURL" class="c-input-select text-black" :rules="{required:true}"  v-model="model.shed_no"></validated-ajax-vue-select>

            </div>
            <div class="col-lg-2">
                <validated-input class="text-black " v-model="model.quantity_supplied" label="Quantity Supplied" :rules="rules.positiveOnly" type="number" ></validated-input>
            </div>
             <div class="col-lg-2">
                <validated-input class="text-black " v-model="model.dm" label="DM" :rules="{required:true}"></validated-input>
            </div>
        </div></div>
        <div class="column fl-x-br pt-7">
            <div class="btn-group">
            <btn text="Save" :loading="loading" loading-text="Saving..." class="px-4"></btn>
            <btn text="Cancel" type="reset" @click="BackToHome" design="basic-b" class="px-4"></btn>
            </div>
        </div>
       </s-form>
    </div>
</template>

<script>
import urls from '../../data/urls';
import axios from 'secure-axios';
import masterURLs from '../../data/masterURLs';

export default {
    name: 'FodderDistribution',
    data () {
        return {
            loading: false,
            URL: urls.fodder_distribution.addEdit,
            model: {
                distribution_date: '',
                crop_variety: '',
                shed_no: '',
                quantity_supplied: '',
                dm: ''
            },
            cropOptions: [
                { label: 'Crop', value: 'Crop' },
                { label: 'Variety', value: 'Variety' },
                { label: 'Type', value: 'Type' }
            ],
            shedOptionsURL: masterURLs.master.shed.vueSelect,
            rules: {
                common: {
                    required: true,
                    customValidator: (value) => {
                        return this.validationDate(value);
                    }
                },
                positiveOnly: {
                    required: false,
                    customValidator: (value) => {
                        return this.avoidNegetive(value);
                    }
                }
            }

        };
    },
    methods: {
        BackToHome () {
            this.$router.push({ path: '/fodder/' });
        },
        avoidNegetive (no) {
            const num = no;
            if (num < 0) {
                return 'Please enter a valid number';
            }

            return true;
        },
        validationDate () {
            const checkDate = this.model.distribution_date;

            const checkDateParts = checkDate.split('-');
            if (checkDateParts.length !== 3) {
                return 'Please enter a valid DOB in the format DD-MM-YYYY.';
            }
            const checkDateDay = parseInt(checkDateParts[0], 10);
            const checkDateMonth = parseInt(checkDateParts[1], 10);
            const checkDateYear = parseInt(checkDateParts[2], 10);

            const checkDateObj = new Date(checkDateYear, checkDateMonth - 1, checkDateDay);

            const currentDate = new Date();
            currentDate.setHours(0, 0, 0, 0);
            if (checkDateObj > currentDate) {
                return 'Future Date not allowed.';
            }
            return true;
        },
        async addItem () {
            try {
                this.loading = true;
                const response = await axios.form(urls.fodder_distribution.addEdit, this.model);
                const result = response.data;
                if (result.success) {
                    this.$notify('Added Successfully', 'Success', { type: 'success' });
                    this.loading = false;
                    await this.$router.push({ path: '/fodder/' });
                } else {
                    const errors = response.data.errors;

                    for (const key in errors) {
                        if (Object.prototype.hasOwnProperty.call(errors, key)) {
                            let errorMessage = '';
                            if (key === '__all__') {
                                errorMessage = `${errors[key]}`;
                            } else {
                                errorMessage = `${key}:  ${errors[key]}`;
                            }
                            this.$notify(errorMessage, 'Warning', { type: 'warning' });
                        }
                    }
                    this.loading = false;
                }
            } catch (error) {
                this.$notify(
                    'Something Went Wrong..!!',
                    error.response.status + ' ' + error.response.statusText,
                    { type: 'danger' }
                );
                this.loading = false;
            }
        }
    }
};
</script>

<style scoped>

</style>
